import { nanoid } from "nanoid";
import React, { useContext } from "react";
import Constants from "./constants";
import { AppContext } from "./context";

function Sidebar() {
  const { showNow, setShowNow } = useContext(AppContext);
  const element = (e) => (
    <a
      className={"list-group-item list-group-item-light p-3"}
      style={showNow === e.showNow ? { background: "lightblue" } : {}}
      href="#!"
      onClick={() => setShowNow(e.showNow)}
      key={nanoid()}
    >
      {e.title}
    </a>
  );

  const sideList = Constants.sideBarElements.map((e) => element(e));

  return (
    <div className="border-end bg-white" id="sidebar-wrapper">
      <div className="sidebar-heading border-bottom bg-light">
        {Constants.title}
      </div>
      <div className="list-group list-group-flush">{sideList}</div>
    </div>
  );
}

export default Sidebar;
