import React, { useState } from "react";
import Select from "react-select";
import "../App.css";
import { AppContext } from "../main/context";

function SelectField(props) {
  const { flashRequired } = useState(AppContext);
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: "black",
      background: flashRequired && !props.value ? "red" : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted white",
      color: "black",
    }),
    singleValue: (provided, state) => {
      const opacity = 1;
      const color = "black";
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition, color };
    },
  };

  return (
    <div className={props.type.divCss}>
      <div className="field-title">{props.type.fieldTitle}</div>
      <Select
        styles={customStyles}
        defaultValue={props.value}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        options={props.type.selectOptions}
      />
    </div>
  );
}

export default SelectField;
