import React from "react";
import "../App.css";

function CheckBox(props) {
  const onChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className={props.type.divCss}>
      <input
        type="checkbox"
        name={props.type.fieldName}
        checked={props.value}
        className="form-check-input p-2 m-2"
        onChange={(e) => onChange(!props.value)}
        readOnly={props.type.readOnly}
      ></input>
      <label
        className="form-check-label m-1 field-title"
        htmlFor={props.type.fieldName}
      >
        {props.type.fieldTitle}
      </label>
    </div>
  );
}

export default CheckBox;
