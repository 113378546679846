import { AppContextProvider } from "./main/context";
import Main from "./main/Main";

function App() {
  return (
    <AppContextProvider>
      <Main />
    </AppContextProvider>
  );
}

export default App;
