import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../App.css";

function DateField(props) {
  var value;
  try {
    value = moment(props.value).toDate();
  } catch (e) {
    value = moment(Date.now()).toDate;
  }

  return (
    <div className={props.type.divCss}>
      <span className="field-title">{props.type.fieldTitle}</span>
      <DatePicker
        selected={value}
        className="wide p-1 m-1 "
        dateFormat={props.type.format || "yyyy / MM / dd"}
        onChange={(e) => {
          if (props.onChange) props.onChange(e.getTime());
        }}
      ></DatePicker>
    </div>
  );
}

export default DateField;
