import React, { useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "../App.css";
import Constants from "./constants";
import { AppContext } from "./context";
import { Cloud } from "./cloud";
const sha1 = require("sha1");

const LoginWidget = (props) => {
  const { setAuthenticated } = useContext(AppContext);
  var username = "";
  var password = "";

  const formSubmit = async (e) => {
    e.preventDefault();
    if (username && password) {
      var response = await Cloud.post(`${Constants.apiBaseUrl}`, {
        action: "LOGIN",
        data: {
          username: username,
          password: sha1(password.trim()),
        },
      });
      if (response) {
        if (response.token) {
          Cloud.setToken(response.token);
          setAuthenticated(true);
          return;
        }
      }
    }
  };

  return (
    <div className="container center">
      <div className="wrapper center">
        <div className="logo">
          <img src="logo512.png" width="50%" alt="Logo" />
        </div>
        <form className="p-3 mt-3" onSubmit={formSubmit}>
          <div className="form-field d-flex align-items-center">
            <span className="far fa-user"></span>
            <input
              type="text"
              name="userName"
              placeholder="Email ID"
              className="wide p-2 m-2"
              onChange={(e) => {
                username = sha1(e.target.value.trim().toLowerCase());
              }}
            ></input>
          </div>
          <div className="form-field d-flex align-items-center">
            <span className="fas fa-key"></span>
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="wide p-2 m-2"
              onChange={(e) => {
                password = sha1(e.target.value.trim());
              }}
            ></input>
          </div>
          <button className="btn btn-dark mt-3">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginWidget;
